import React from "react";
import HowItWorks from "./it-works";

const AppSection: React.FC = () => {
  return (
    <section id="app" className="w-full h-full py-12 border-b border-black/10">
      <div className="w-full max-w-screen-xl mx-auto flex flex-wrap justify-between items-start gap-7 px-4 py-12">
        <div className="w-full lg:w-6/12 flex flex-col justify-start items-start gap-[47px]">
          <div className="relative w-full h-[134px]">
            <div className="absolute top-0 left-0 text-black text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
              The
            </div>
            <div className="absolute top-[68px] text-[#c84e41] text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
              Peacemaker{" "}
              <span className="text-black text-[50.66px] font-normal font-['Imprima']">
                App
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-[37px]">
            <div className="text-black text-lg font-semibold font-['Plus Jakarta Sans'] leading-7">
              PeaceMaker is a digital payment mediation platform designed to
              address fraud and mistrust in online transactions, particularly in
              regions like Africa where these issues are prevalent. Our platform
              holds payments securely until both parties fulfill their
              obligations, verified through secret fulfillment codes. We aim to
              create a safer and more trustworthy environment for digital
              transactions, facilitating commerce and reducing fraud in the
              process.
            </div>
            <HowItWorks />
          </div>
          <div className="h-[54.23px] justify-start items-center gap-[16.27px] inline-flex">
            <a href="https://googleplay.com/">
              <img
                className="w-[183.03px] h-[54.23px] relative bg-black rounded-md border border-[#a6a6a6]"
                src="/images/google.png"
                alt="Google Play"
              />
            </a>
            <a href="https://appstore.com/">
              <img
                className="w-[162.70px] h-[54.23px] relative bg-[#0c0d10] rounded-[9.49px] border border-[#a6a6a6]"
                src="/images/apple.png"
                alt="Google Play"
              />
            </a>
          </div>
        </div>
        <div className="w-full h-[963px] object-cover lg:w-5/12 relative bg-white rounded-full">
          <img
            className="absolute top-[0px] left-0 w-180 h-[963px] object-cover"
            src="/images/app.png"
            alt="App"
          />
        </div>
      </div>
    </section>
  );
};

export default AppSection;
