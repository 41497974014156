const AboutSection = () => {
  return (
    <section className="w-full h-auto px-4 md:px-12 py-[140px] border-b border-black/10 flex flex-col justify-center items-center">
      <div className="max-w-7xl w-full flex flex-col gap-[100px]">
        <div className="flex flex-col md:flex-row justify-start items-start gap-[47px]">
          <div className="w-full md:w-[540px] text-black text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
            Why transact with Peacemaker
          </div>
          <div className="w-full md:w-[626px] flex flex-col justify-start items-start gap-[37px]">
            <div className="text-black text-lg font-semibold font-['Plus Jakarta Sans'] leading-7">
              We understand the need for secure and reliable payment processing
              for your business. Our service protects your transactions and
              sensitive information, allowing you to focus on growth. With our
              secure payment service, you can trust in efficient, accurate, and
              safe transactions, giving you a competitive edge in the digital
              landscape.
            </div>
            <div className="px-[18px] py-2.5 bg-[#552130] rounded shadow border border-[#552130] flex justify-center items-center gap-2">
              <div className="text-white text-base font-semibold font-['Inter'] leading-normal">
                Explore more
              </div>
              <img
                className="w-6 h-6 relative"
                src="/images/explore.png"
                alt="Google Play"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-start items-start gap-[26px]">
          <div className="w-full md:w-[388px] flex flex-col justify-start items-start gap-5">
            <div className="w-12 h-12 p-3 bg-[#fff1e5] rounded-[28px] border-1 border-[#fff1f1] flex justify-center items-center">
              <img
                className="w-6 h-6 relative flex justify-start items-start"
                src="/images/instant.png"
                alt="Google Play"
              />
            </div>
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="text-[#0f1728] text-xl font-bold font-['Plus Jakarta Sans'] leading-[30px]">
                Fast instant payments
              </div>
              <div className="text-[#475466] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
                An all-in-one customer service platform that helps you balance
                everything your customers need to be happy.
              </div>
            </div>
          </div>
          <div className="w-full md:w-[388px] flex flex-col justify-start items-start gap-5">
            <div className="w-12 h-12 p-3 bg-[#fff1e5] rounded-[28px] border-1 border-[#fff1f1] flex justify-center items-center">
              <img
                className="w-6 h-6 relative flex justify-start items-start"
                src="/images/confirmation.png"
                alt="Google Play"
              />
            </div>
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="text-[#0f1728] text-xl font-bold font-['Plus Jakarta Sans'] leading-[30px]">
                Secured payment confirmations
              </div>
              <div className="text-[#475466] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
                Whether you have a team of 2 or 200, our shared team inboxes
                keep everyone on the same page and in the loop.
              </div>
            </div>
          </div>
          <div className="w-full md:w-[388px] flex flex-col justify-start items-start gap-5">
            <div className="w-12 h-12 p-3 bg-[#fff1e5] rounded-[28px] border-1 border-[#fff1f1] flex justify-center items-center">
              <img
                className="w-6 h-6 relative flex justify-start items-start"
                src="/images/manage-transactions.png"
                alt="Google Play"
              />
            </div>
            <div className="flex flex-col justify-start items-center gap-2">
              <div className="text-[#0f1728] text-xl font-bold font-['Plus Jakarta Sans'] leading-[30px]">
                Manage your payment transactions
              </div>
              <div className="text-[#475466] text-base font-semibold font-['Plus Jakarta Sans'] leading-normal">
                Measure what matters with Untitled’s easy-to-use reports. You
                can filter, export, and drilldown on the data in a couple
                clicks.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
