import React from "react";

const NewsLetterSection: React.FC = () => {
  return (
    <section
      id="subscribe"
      className="w-full py-12 bg-[#662424] flex justify-center items-center"
    >
      <div className="w-full max-w-screen-xl px-4 flex flex-col lg:flex-row justify-between items-center">
        <div className="text-left lg:w-1/2">
          <h2 className="text-white text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
            Newsletter
          </h2>
          <p className="text-white text-lg font-semibold font-['Plus Jakarta Sans'] leading-7 mt-[26px]">
            Subscribe to our news letter,
            <br />
            to receive all latest news and update from peace maker and the
            financial world.
          </p>
        </div>
        <div className="mt-8 lg:mt-0 flex justify-center items-center gap-4 lg:w-1/2">
          <input
            type="email"
            placeholder="Enter your email"
            className="pl-[19px] py-4 w-full max-w-sm bg-[#fef7ff]/90 rounded-l-xl text-[#47545f] text-[17px] font-semibold font-['Quicksand'] leading-[25px]"
          />
          <button className="px-5 py-4 bg-white rounded-r-xl text-[#0d1216] text-base font-bold font-['Quicksand'] leading-[25px]">
            🚀 Subscribe
          </button>
        </div>
      </div>
    </section>
  );
};

export default NewsLetterSection;
