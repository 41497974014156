import React, { useEffect, useState } from "react";
import Menu from "./menu";

const HeaderSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="left-0 relative bg-white right-0">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/videos/background-video.mp4"
        autoPlay
        loop
        muted
      />

      {/* Overlay for dimming the video */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

      <Menu isScrolled={isScrolled} />
      {/* Main Section */}
      <section
        id="header"
        className="relative h-[900px] right-0 xs:pt-[150px] lg:pt-[0px] px-4 md:px-8 lg:px-12 flex flex-col lg:flex-row justify-between items-center"
      >
        {/* Left Content */}
        <div className="flex flex-col items-start gap-[30px] mb-12 lg:w-1/2">
          {/* Tagline */}
          <div className="xs:px-5 xs:mx-5 lg:mt-[-100px]">
            <div className="text-white mt-10 text-[36px] md:text-[50.66px] font-normal font-['Imprima'] leading-tight md:leading-[63px]">
              Providing confidence in every{" "}
              <span className="text-[#552130] bg-white px-3">transaction</span>
            </div>
            <div className="lg:mt-5 text-lg md:text-xl font-semibold font-['Plus Jakarta Sans'] leading-7 text-white">
              Ensuring trust in digital payments, prioritizing security and
              transparency in every transaction, Peacemaker ensures that both
              buyers and sellers can engage with peace of mind, knowing their
              funds are protected and safeguarded at every step.
            </div>
          </div>
          {/* CTA Buttons */}
          <div className="flex gap-3 lg:mt-10 xs:px-5 xs:mx-5">
            <div className="flex justify-center items-center px-[18px] py-2.5 bg-white rounded-lg shadow border border-[#cfd4dc]">
              <a href="https://app.peacemaker.cash/">
                <div className="w-[143px] text-center text-[#344053] text-base font-semibold font-['Inter'] leading-normal">
                  Log In
                </div>
              </a>
            </div>
            <a href="https://app.peacemaker.cash/">
              <div className="flex justify-center items-center px-[18px] py-2.5 bg-[#552130] rounded shadow border border-[#552130]">
                <div className="text-white text-base font-semibold font-['Inter'] leading-normal">
                  Create an account
                </div>
              </div>
            </a>
          </div>
          {/* Join Us Section */}
          <div className="w-[466px] h-14 relative lg:bottom-[-230px] mx-10">
            <div className="w-[329px] left-[137px] top-0 absolute">
              <span className="text-zinc-300 text-lg font-normal font-['Plus Jakarta Sans'] leading-7">
                Join{" "}
              </span>
              <span className="text-[#552130] bg-white px-3 text-lg font-bold font-['Plus Jakarta Sans'] leading-normal">
                4000+ Individuals
              </span>
              <span className="text-zinc-200 text-lg font-normal font-['Plus Jakarta Sans'] leading-7">
                {" "}
                to send and receive money in Ghana and Nigeria{" "}
              </span>
            </div>
            <div className="w-[120px] h-[53px] left-0 top-0 absolute">
              <img
                className="w-[53px] h-[53px] left-0 top-0 absolute rounded-full border-2 border-white"
                src="/images/user-1.png"
                alt=""
              />
              <img
                className="w-[53px] h-[53px] left-[33px] top-0 absolute rounded-full border-2 border-white"
                src="/images/user-2.png"
                alt=""
              />
              <img
                className="w-[53px] h-[53px] left-[67px] top-0 absolute rounded-full border-2 border-white"
                src="/images/user-3.png"
                alt=""
              />
            </div>
          </div>
        </div>
        {/* Right Image */}
        <div className="relative lg:w-1/2 mr-5">
          <div className="absolute top-[18px] left-[10%] right-[0%] h-[384.16px] bg-gradient-to-r from-[#552130] via-[#c84e41] to-[#ff675d] blur-[59.10px] rounded-[19.36px]"></div>
          <img
            className="w-full rounded-[19.36px] shadow-2xl relative z-10"
            src="/images/home.png"
            alt="Home"
          />
          <div className="absolute md:w-[150px] xs:w-[150px] lg:w-[270px] top-[53px] right-[-5%] bg-gradient-to-br from-[#040404] to-[#2f324a] rounded-[9.01px] shadow z-20">
            <img
              className="rounded-[19.36px]"
              src="/images/card-web.png"
              alt="Card Web"
            />
          </div>

          <div className="absolute lg:w-[570px] lg:bottom-[-300px] lg:left-[-3.5%] sm:w-0 xs:w-0 rounded-[9.01px] z-20">
            <img
              className="rounded-[19.36px]"
              src="/images/app.png"
              alt="Card Web"
            />
          </div>
        </div>
      </section>
    </header>
  );
};

export default HeaderSection;
