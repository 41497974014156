const HowItWorks = () => {
  return (
    <div className="w-[600px] h-[504px] flex-col justify-start items-start gap-[26px] inline-flex">
      <div className="self-stretch text-black text-4xl font-normal font-['Imprima'] leading-[66px]">
        How it works{" "}
      </div>
      <div className="self-stretch h-[412px] flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[16px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              1
            </div>
          </div>
          <div className="text-[#0d1216] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Download our app from the app or play store{" "}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[14px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              2
            </div>
          </div>
          <div className="w-[341px] text-[#0d1216] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Enter number and complete profile{" "}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[15px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              3
            </div>
          </div>
          <div className="grow shrink basis-0 text-[#0d1216] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Create virtual cards to aid deal transactions{" "}
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[14px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              4
            </div>
          </div>
          <div className="grow shrink basis-0 text-[#0d1216] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Begin deals with individuals on your contact list or not
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[14px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              5
            </div>
          </div>
          <div className="grow shrink basis-0 text-[#0d1216] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Track deal progress
          </div>
        </div>
        <div className="self-stretch justify-start items-center gap-[22px] inline-flex">
          <div className="w-[42px] h-[42px] relative">
            <div className="w-[42px] h-[42px] left-0 top-0 absolute bg-gradient-to-r from-[#f7bfa0] to-[#a0616a] rounded-full" />
            <div className="left-[14px] top-[7px] absolute text-white text-[22px] font-semibold font-['Plus Jakarta Sans'] leading-7 tracking-tight">
              6
            </div>
          </div>
          <div className="grow shrink basis-0 text-[#161616] text-xl font-semibold font-['Plus Jakarta Sans'] leading-loose">
            Stay connected socially with dealers with in-built chats
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
