import AboutSection from "../components/about";
import AppSection from "../components/app";
import BranchSection from "../components/branches";
import FooterSection from "../components/footer";
import HeaderSection from "../components/header";
import HowSection from "../components/how";
import NewsLetterSection from "../components/new";
import GetStartedSection from "../components/started";

const HomePage = () => {
  return (
    <div>
      {/* Header */}
      <HeaderSection />

      {/* About section */}
      <AboutSection />
      {/* How It Works */}
      <HowSection />
      {/* Branch section */}
      <BranchSection />
      {/* App Section */}
      <AppSection />
      {/* News Letter */}
      <NewsLetterSection />
      {/* Get Started */}
      <GetStartedSection />
      {/* Footer section */}
      <FooterSection />
    </div>
  );
};

export default HomePage;
